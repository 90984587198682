const routes = [
  {   
   path: '/',   
   name: 'index',    
   component: () => import(/* webpackChunkName: "body" */ '../page/index.vue'),
   meta: {
        title: 'Home'
      }, 
  }, 
  {
    path: '/circle', 
    name: 'circle',
    component: () => import(/* webpackChunkName: "login" */ '../page/circle.vue'),
     meta: {
        title: 'History',
      }, 
  }, 
  {
    path: '/case', 
    name: 'case',
    component: () => import(/* webpackChunkName: "login" */ '../page/case.vue'),
     meta: {
        title: 'Design Service & Cases',
      }, 
  }, 
  {
    path: '/cookieSettings', 
    name: 'cookieSettings',
    component: () => import(/* webpackChunkName: "login" */ '../page/cookieSettings.vue'),
     meta: {
        title: 'Cookie Settings',
      }, 
  }, 
  {
    path: '/cookiePolicy', 
    name: 'cookiePolicy',
    component: () => import(/* webpackChunkName: "login" */ '../page/cookiePolicy.vue'),
     meta: {
        title: 'Cookie Policy',
      }, 
  }, 
  {
    path: '/privacyPolicy', 
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "login" */ '../page/privacyPolicy.vue'),
     meta: {
        title: 'Privacy Policy',
      }, 
  },
  {
    path: '/termsOfUse', 
    name: 'termsOfUse',
    component: () => import(/* webpackChunkName: "login" */ '../page/termsOfUse.vue'),
     meta: {
        title: 'Terms of Use',
      }, 
  }, 
  {
    path: '/history', 
    name: 'history',
    component: () => import(/* webpackChunkName: "login" */ '../page/history.vue'),
     meta: {
        title: 'Our History',
      }, 
  }, 
  {
    path: '/:error*',
    name: '404',
    meta: {
      title: 'PAGE NOT FOUND'
    },
    component: () => import('../page/404.vue'),
  }
]; 

export default routes