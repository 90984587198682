
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style>
@import url('./assets/css/common.css');
html,body{
  margin: 0;
  padding: 0;
}
#app {
	font-family: 'Univers LT Std LightCn';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-size: 18px;
}

</style>
